// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"
import useWilhelm from '../hooks/useWilhelm'
import Layout from "../components/layout"
import SEO from "../components/seo"




const Wilhelm: React.FC<PageProps> = ({

  location,
}) => {

  useWilhelm();

  return (
    <Layout title="Use Wilhelm" location={location}>
      <SEO title="Use Wilhelm" />
      <p>Is responsive design sometimes painful? Now the world can hear your pain! Resize your browser to hear the effects. </p>

    </Layout>
  )
}

export default Wilhelm


