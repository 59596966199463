import { useEffect, useState } from "react"

const useWilhelm = (audioFile = 'wilhelm.mp3') => {

  const [isPlaying, setIsPlaying] = useState(false)
  useEffect(() => {
    const handleResize = () => {
      if (!isPlaying) {
        setIsPlaying(true)
        audioElement.play();
        setIsPlaying(false)
      }
    };

    const audioElement = document.createElement('audio');
    audioElement.setAttribute('src', audioFile);
    window.addEventListener('resize', () => handleResize())

  }, [])

}


export default useWilhelm;